<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Notification
      </h3>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <div class="panel">
          <el-table :data="notificationLogData.logs">
            <el-table-column prop="created_at" label="Created date" />
            <el-table-column prop="work_at" label="Worked at">
              <template slot-scope="scope">
                {{
                  scope.row.work_at === "Invalid Date"
                    ? "Null"
                    : scope.row.work_at
                }}
              </template>
            </el-table-column>
            <el-table-column prop="is_worked" label="Is worked" width="120px">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  effect="dark"
                  :type="scope.row.is_worked == false ? 'danger' : 'success'"
                >
                  {{ scope.row.is_worked == false ? "Pending" : "Sent" }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "@/helpers/services.js";

export default {
  name: "NotificationsById",
  mounted() {
    if (this.$route.params.hasOwnProperty("id")) {
      this.notificationId = this.$route.params.id;
      this.getNotificationDetails(this.notificationId);
    }
  },
  data() {
    return {
      notificationId: -1,
      notificationLogData: {
        logs: []
      }
    };
  },
  methods: {
    getNotificationDetails(id) {
      service
        .getNotificationDetails(id)
        .then(response => {
          if (response.status == "success") {
            this.notificationLogData = response.data;
          } else {
            this.$notify({
              title: "Анхааруулга",
              message: "Алдаа: " + response.error.message,
              type: "error"
            });
          }
        })
        .catch(error => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа: " + error.message,
            type: "error"
          });
        });
    }
  }
};
</script>

<style></style>
